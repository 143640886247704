import React from 'react';

import { ACTIVITY_RESULTS } from '../../constans/activity-search';
import { formatCurrency, pvPipe } from '../../helpers/helper-methods';

import styles from './index.module.scss';

const ActivityPvCard = ({
  id,
  index,
  title,
  PV,
  page,
  country,
  city,
  region,
  district,
  price,
  activeId,
  currency = 'USD',
  firstPhoto,
  onClick = () => { },
}) => (
  <div onClick={() => onClick(id)} className={`${styles.activity} ${id === activeId && styles.active}`}>
    {firstPhoto && <div className={styles.imageBlock}>
      <img src={firstPhoto.file?.link} className={styles.img} alt="activity" />
    </div>}
    <div className={styles.infoBlock}>
      <div className={`text-truncate ${styles.activityTitle}`}>{title}</div>
      <div className={`text-truncate ${styles.divider}`} />
      <div className={`text-truncate ${styles.activityDestination}`}>{city.name}</div>
      <div className={styles.priceBlock}>
        <div className={styles.activityPV}>{pvPipe(index, PV, ACTIVITY_RESULTS.PER_PAGE, page)}</div>
        <div className={styles.activityPrice}>{price ? formatCurrency(currency, price) : 'Free'}</div>
      </div>
    </div>
  </div>
);

export default ActivityPvCard;
