/* eslint-disable react/jsx-one-expression-per-line */
import React, {
  Fragment, useCallback, useEffect, useRef, useState,
} from 'react';
import { Formik, Form } from 'formik';
import FormikStateChangeHandler from '../FormikStateChangeHandler/FormikStateChangeHandler';
import FiltersIcon from '../../assets/img/icons/ic_filters.svg';
import Checkbox from '../UI/Checkbox/Checkbox';
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { useLockBodyScroll } from '../../hooks/useBodyScrollLock';
import { TABLET_WIDTH_BREAKPOINT } from '../../constans/header';

import styles from './SearchFilters.module.scss';
import useOutsideClick from '../../hooks/useOutsideClick';

const SearchFilters = ({
  filterOptions,
  initialValues,
  selectedValues,
  containerClassName,
  togglerClassName,
  onSubmit,
  roomTypes,
  roomTypesName,
  enableReinitialize = false,
  searchFilterChangeHandler,
  supposedPropertiesAmount,
  propertiesFiltersLoading,
  propertiesFilter,
  resetState,
  resetButtonText = 'Reset',
}) => {
  const ref = useRef();
  const [isShown, setIsShown] = useState(false);
  const handleToggle = () => {
    setIsShown(!isShown);
  };
  const windowWidth = useWindowWidth();
  const { enable, disable } = useLockBodyScroll();

  const backdropShown = isShown && windowWidth <= TABLET_WIDTH_BREAKPOINT;

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    backdropShown
      ? disable()
      : enable();

    return () => enable();
  }, [isShown]);

  useOutsideClick(ref, () => {
    if (isShown) setIsShown(false);
  });

  return (
    <>
      <div>
        <button
          className={`${styles.toggler} ${togglerClassName}`}
          type="button"
          onClick={handleToggle}
        >
          <FiltersIcon />
        </button>
      </div>
      {backdropShown && (
        <div
          className={styles.filtersBackdrop}
          onClick={() => setIsShown(false)}
        />
      )}
      <div ref={ref} className={`${containerClassName} ${styles.filters} ${isShown && styles.active}`} id="filterModl">
        <Formik
          enableReinitialize={enableReinitialize}
          initialValues={{ ...initialValues, ...selectedValues }}
          onSubmit={onSubmit}
        >
          {({ resetForm }) => (
            <Form>
              {searchFilterChangeHandler
                ? <FormikStateChangeHandler changeHandler={searchFilterChangeHandler} />
                : null}
              <div className="flex flex-v-center flex-between">
                <div className={styles.filtersTitle}>
                  Filters
                </div>
                <button onClick={() => resetForm({ values: resetState || initialValues })} type="button" className={styles.filtersTitle}>
                  {resetButtonText}
                </button>
              </div>
              {filterOptions?.map(({ label, name, options: filters }) => (
                <Fragment key={name}>
                  <div className={styles.divider} />
                  <div className={styles.filtersSection}>
                    <div className={styles.filtersSectionTitle}>
                      {label}
                    </div>
                    {filters.map(({ title, value, disabled }) => (
                      <Checkbox
                        disabled={propertiesFiltersLoading || disabled}
                        key={value}
                        name={value}
                        containerClasses={styles.checkboxWrapper}
                        checkboxClasses="c-custom__check-bordered-primary c-custom__check-md"
                        textClasses={`${styles.checkboxLabel} text-truncate`}
                        labelClasses={`${styles.checkboxWrapper} styles.checkbox`}
                      >
                        {title}
                      </Checkbox>
                    ))}
                  </div>
                </Fragment>
              ))}
              {roomTypes && (
                <Fragment key={roomTypesName}>
                  <div className={styles.divider} />
                  <div className={styles.filtersSection}>
                    <div className={styles.filtersSectionTitle}>
                      Room type
                    </div>
                    <CheckboxGroup
                      name={roomTypesName}
                      options={roomTypes}
                      valueField="value"
                      checkboxClassName={styles.checkbox}
                      wrapperClassName={styles.checkboxWrapper}
                      titleClassName={`${styles.checkboxLabel} text-truncate`}
                    />
                  </div>
                </Fragment>
              )}
              {propertiesFilter ? (
                <>
                  <div className={`${styles.supposedResultsBlock} flex flex-col flex-v-center t-500 mb-1em`}>
                    {supposedPropertiesAmount > 0
                      ? (
                        <>
                          <div>
                            {supposedPropertiesAmount}
                            {' '}
                            propert{supposedPropertiesAmount === 1 ? 'y' : 'ies'}
                          </div>
                          <div>can be found</div>
                        </>
                      )
                      : <div className="text-center">No properties can be found</div>}
                  </div>
                  <button
                    disabled={supposedPropertiesAmount < 1 || propertiesFiltersLoading}
                    type="submit"
                    className={`${styles.button} btn btn_inverted`}
                    onClick={handleToggle}
                  >
                    apply
                  </button>
                </>
              ) : (
                <button
                  type="submit"
                  className={`${styles.button} btn btn_inverted`}
                  onClick={handleToggle}
                >
                  apply
                </button>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SearchFilters;
