import React, { forwardRef } from 'react';
import { Consumer } from './Context';

import styles from './Tabs.module.scss';

const Tabs = forwardRef(({
  switchers,
  className,
  switchersClassName,
  switchButtonClassName,
  activeClassName,
  tabsWithFiltersIds = [],
  children,
  Filter,
  before,
  after,
}, ref) => (
  <div className={className}>
    <Consumer>
      {({ activeIndex, setActiveIndex }) => (
        <div ref={ref} className={`${switchersClassName} flex flex-between`}>
          <div>
            {before}
            {switchers.map((switcher, index) => (
              <button
                className={`${styles.switcher} ${activeIndex === index && `${styles.active} && ${activeClassName}`} ${switchButtonClassName}`}
                onClick={() => setActiveIndex(index)}
                type="button"
                key={index}
              >
                {switcher}
              </button>
            ))}
            {after}
          </div>
          {tabsWithFiltersIds.includes(activeIndex) && Filter}
        </div>
      )}
    </Consumer>
    {children}
  </div>
));

Tabs.defaultProps = {
  switcherStyles: '',
};

export default Tabs;
