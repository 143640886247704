import React from 'react';
import { Consumer } from './Context';

const Panel = ({ tabId, children }) => (
  <Consumer>
    {({ activeIndex }) => {
      const isActive = tabId === activeIndex;
      return isActive && <>{children}</>;
    }}
  </Consumer>
);

export default Panel;
