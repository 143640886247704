import React, { createContext, useState } from 'react';

export const ActiveTabContext = createContext({
  activeIndex: 0,
  setActiveIndex: () => { /* do noting */ },
});

export const Provider = ({ children, defaultActiveIndex = 0 }) => {
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);

  const activeTabContext = {
    activeIndex,
    setActiveIndex,
  };

  return <ActiveTabContext.Provider value={activeTabContext}>{children}</ActiveTabContext.Provider>;
};

export const { Consumer } = ActiveTabContext;
