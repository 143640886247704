import http from './http.service';

const activityReviewService = () => {
  const basePath = '/activity-reviews';

  const getActivityReviewTimings = (id) => http.get(`${basePath}/${id}/timings`);
  const getActivityReviewPricing = (id) => http.get(`${basePath}/${id}/pricings`);

  return {
    getActivityReviewTimings,
    getActivityReviewPricing,
  };
};

export default activityReviewService;
