import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const FormikStateChangeHandler = ({ changeHandler }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    changeHandler(values);
  }, [values]);
  return null;
};

FormikStateChangeHandler.defaultProps = {
  changeHandler: () => {},
};

export default FormikStateChangeHandler;
