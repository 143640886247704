import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import { navigate } from 'gatsby';

import { Consumer, Provider } from '../../../../components/Tabs/Context';
import { getQueryParams, objectToActiveKeysArray } from '../../../../helpers/helper-methods';
import activitiesService from '../../../../services/activitiesService';
import Layout from '../../../../components/Layout/Layout';
import Pagination from '../../../../components/Pagination';
import ActivityPvCard from '../../../../components/ActivityPvCard';
import { ACTIVITY_RESULTS_PV } from '../../../../constans/activity-search';
import ActivityPage from '../../../../views/activity-page';
import SearchFilters from '../../../../components/SearchFilters/SearchFilters';
import { ACTIVITY_CATEGORY_OPTIONS, ACTIVITY_SEARCH_RESULTS_OPTIONS } from '../../../../constans/activity';
import { TABLET_WIDTH_BREAKPOINT } from '../../../../constans/header';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';
import Arrow from '../../../../assets/img/icons/ic-chevron-right-light-gray.svg';

import styles from './index.module.scss';
import { isAuthGuard } from '../../../../guards/isAuthGuard';
import { MODAL_TIMEOUT_TIME } from '../../../../constans/search';
import SignUpModal from '../../../../components/AutorizationModal/SignUpModal';

function ActivitySearchAdvancedResults({ location }) {
  const { search } = location;
  const {
    uuid,
    // adults,
    // kids,
    currency,
    filters,
    locationName,
    categoriesFilters,
    //  checkin, checkout,
  } = getQueryParams(search);

  const initialFiltersValues = useMemo(() => {
    if (categoriesFilters && !filters) {
      const initialFilters = ACTIVITY_SEARCH_RESULTS_OPTIONS;
      categoriesFilters.forEach((filter) => {
        initialFilters[filter] = true;
      });
      return initialFilters;
    }

    return filters?.length
      ? filters?.map((key) => ({ [key]: false }))
        .reduce((acc, currentValue) => ({ ...acc, ...currentValue }))
      : ACTIVITY_SEARCH_RESULTS_OPTIONS;
  }, [categoriesFilters, filters]);

  const activeFilterOptions = useMemo(() => {
    if (categoriesFilters && !filters) {
      const options = ACTIVITY_CATEGORY_OPTIONS;
      categoriesFilters.forEach((filter) => {
        const foundFilter = options.find((option) => option.value === filter);
        if (foundFilter?.value) {
          const updatedFilter = {
            ...foundFilter,
            disabled: true,
          };

          const index = options.indexOf(foundFilter);

          // Discard results with value -1
          if (~index) {
            options[index] = updatedFilter;
          }

          return options;
        }
      });
    }

    return filters?.length
      ? ACTIVITY_CATEGORY_OPTIONS
        .filter(({ value }) => filters.includes(value))
      : ACTIVITY_CATEGORY_OPTIONS;
  }, [categoriesFilters, filters]);

  const [searchFilters, setSearchFilters] = useState(initialFiltersValues);
  const [totalCount, setTotalCount] = useState();
  const [activities, setActivities] = useState([]);
  const [isInitialResponseEmpty, setIsInitialResponseEmpty] = useState(false);
  const [isInitialRequestSucceed, setIsInitialRequestSucceed] = useState(false);
  const [isFilteredResponceEmpty, setIsFilteredResponceEmpty] = useState(false);
  const [activeId, setActiveId] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [isModalShown, setIsModalShown] = useState(false);

  const isAuth = isAuthGuard();

  const width = useWindowWidth();
  const isTablet = width <= TABLET_WIDTH_BREAKPOINT;

  const getData = (model) => activitiesService().getActivitiesByUUID(model);

  const handleActivityCardClick = (setActiveTab) => (id) => {
    if (isTablet) {
      window?.scrollTo(0, 0);
    }

    setActiveTab(1);
    setActiveId(id);
  };

  const getInitialActivities = async (model) => {
    const { data, pagination } = await getData(model);

    if (data?.length === 0) {
      setIsInitialResponseEmpty(true);
      return;
    }

    setTotalCount(pagination.totalCount);
    setActivities(data);
    setActiveId(data[0]?.id);
    setIsInitialRequestSucceed(true);
  };

  const getActivities = useCallback(async ({
    selected: page = 0,
    limit = ACTIVITY_RESULTS_PV.PER_PAGE,
  }) => {
    const categoriesFilters = objectToActiveKeysArray(searchFilters);

    const model = {
      uuid,
      limit,
      offset: page * ACTIVITY_RESULTS_PV.PER_PAGE,
      categoriesFilters,
    };

    const { data, pagination } = await getData(model);

    if (data?.length === 0) {
      setIsFilteredResponceEmpty(true);
      return;
    }

    setIsFilteredResponceEmpty(false);
    setCurrentPage(page);
    setActivities(data);
    setTotalCount(pagination.totalCount);
    setActiveId(data?.[0]?.id);
  }, [searchFilters]);

  useEffect(() => {
    getInitialActivities({ uuid, limit: ACTIVITY_RESULTS_PV.PER_PAGE });
  }, [uuid]);

  useEffect(() => {
    if (isInitialRequestSucceed) {
      getActivities({ uuid, limit: ACTIVITY_RESULTS_PV.PER_PAGE });
    }
  }, [searchFilters]);

  useEffect(() => {
    setTimeout(() => setIsModalShown(true), MODAL_TIMEOUT_TIME);
  }, []);

  if (isInitialResponseEmpty) {
    return (
      <Layout mainClasses="column-flexed-wrapper">
        <div className="flex flex-grow-1 flex-h-start">
          <div className={`container my-auto ${styles.page}`}>
            <p className={`${styles.nothingFoundText} mb-0`}>
              There is no matching Activity for your search inputs.
            </p>
            <p className={styles.nothingFoundText}>
              We suggest you amend some of the selected options and filters and/or expand
              your search criteria.
            </p>
            <button
              onClick={() => navigate('/')}
              type="button"
              className={`
                ${styles.nothingFoundButton}
                blocked btn btn_mld mx-auto btn_inverted btn-uppercased btn_bordered btn_radius-28 t-700
              `}
            >
              Back to Search
            </button>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout mainClasses="column-flexed-wrapper">
      <div className="flex flex-grow-1 flex-h-start">
        <div className={`container ${styles.page}`}>
          <div className={`flex flex-v-center flex-wrap ${styles.searchCrumbWrapper}`} />
          <Provider>
            <Consumer>
              {({ setActiveIndex, activeIndex }) => (
                <div className={`row ${styles.content}`}>
                  <div className="col-12 col-lg-4">
                    {(!isTablet || (!isFilteredResponceEmpty && activeIndex === 0)) && (
                      <>
                        <div className="flex flex-between">
                          {!!totalCount && <div className={styles.pvTitle}>{`${totalCount} Activities in ${locationName}`}</div>}
                          <SearchFilters
                            resetButtonText="No filter"
                            containerClassName="col-9 col-md-5 col-lg-8"
                            valueField="value"
                            selectedValues={searchFilters}
                            initialValues={initialFiltersValues}
                            onSubmit={(v) => setSearchFilters(v)}
                            filterOptions={[{
                              name: 'values',
                              options: activeFilterOptions,
                            }]}
                          />
                        </div>
                        <div className="flex flex-between flex-wrap">
                          {activities.map((card, index) => (
                            <ActivityPvCard
                              {...card}
                              activeId={activeId}
                              key={card.id}
                              isActive={card.id === activeId}
                              index={index}
                              page={currentPage}
                              currency={currency}
                              onClick={handleActivityCardClick(setActiveIndex)}
                            />
                          ))}
                        </div>

                        <div className={`flex flex-center ${styles.mt30}`}>
                          <Pagination
                            total={totalCount}
                            forcePage={currentPage}
                            perPage={ACTIVITY_RESULTS_PV.PER_PAGE}
                            callback={getActivities}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {((!isFilteredResponceEmpty && activeIndex === 1) || !isTablet) && (
                    <div className="col-12 col-lg-8">
                      <Provider>
                        <Consumer>
                          {() => (
                            <ActivityPage
                              goBackButton={(
                                <button
                                  onClick={() => setActiveIndex(0)}
                                  className={`btn ${styles.buttonBack}`}
                                  type="button"
                                >
                                  <Arrow className={styles.buttonBackArrow} />
                                  Go Back
                                </button>
                              )}
                              id={activeId}
                              tabsClassName={styles.tabs}
                              isSearchResult
                            />
                          )}
                        </Consumer>
                      </Provider>
                    </div>
                  )}
                </div>
              )}
            </Consumer>
          </Provider>
          {!!isFilteredResponceEmpty && (
            <p className={`${styles.nothingFoundText} ${styles.filtered} mx-auto`}>
              No results found
            </p>
          )}
        </div>
      </div>
      <SignUpModal
        isShown={!isAuth && isModalShown}
        toggleModal={() => setIsModalShown()}
        isSignIn={false}
      />
    </Layout>
  );
}

export default ActivitySearchAdvancedResults;
